import logo from "./logo.svg";
import "./App.css";
import Sidebar from "./components/sidebar";
import Overview from "./components/Overview";
import Skill from "./components/Skill";
import Goals from "./components/Goal";
import Projects from "./components/Project";
import ContactForm from "./components/contactForm";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>Portfolio | Muhammad Afaq Arif</title>
        <meta
          name="description"
          content="Portfolio of Muhammad Afaq Arif , a software engineer."
        />
        <meta property="og:title" content="Portfolio | Muhammad Afaq Arif" />
        <meta
          property="og:description"
          content="Portfolio of Muhammad Afaq Arif, a software engineer."
        />
        <meta
          name="keywords"
          content="
Full Stack Developer, Java Developer, React Developer, Spring Boot, Microservices, RESTful APIs, React.js, Node.js, TypeScript, DevOps, AWS, Docker, Jenkins, SQL, MySQL, MongoDB, ElasticSearch, Kubernetes, Agile Development, CI/CD Pipelines, API Integration, Software Development, System Design, Solution Architect, Cloud Development, Secure Authentication, Frontend Development, Backend Development, Scalable Web Applications, Digital Transformation, Enterprise Solutions
"
        />

        <meta
          property="og:url"
          content="https://mafaqarif.github.io/portfolio"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="container-main">
        <Sidebar />
        <div className="portfolio-content">
          <Overview />
          <Skill />
          <Goals />
          <Projects />
          <ContactForm />
        </div>
      </div>
    </>
  );
}

export default App;
